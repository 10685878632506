/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */

import React, { useState, useEffect } from 'react';

import {
  Card, Empty, Row, Select, Tabs, Divider, DatePicker, Table, Spin,
} from 'antd';

import {
  each, map, filter, find, get, sumBy, isEqual, isEmpty, cloneDeep, round, isPlainObject, isArray, set,
  concat,
} from 'lodash';

import moment from 'moment';
import Global from '../commons/Global';
import Model from '../services/model';

const { Option } = Select;

const Report = (props) => {
  const {
    pipe, report, content, refresh,
  } = props;

  const { t } = pipe.translate;

  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [shops, setShops] = useState();
  const [reports, setReports] = useState([]);
  const [selectedShop, setSelectedShop] = useState();
  const [meta, setMeta] = useState({});

  const processReport = (report) => {
    report.classes = cloneDeep(filter(report.attendence, ({ cancel, deactivated }) => !cancel && !deactivated));
    const staffs = [];
    each(report.classes, ({ classInfo, subsitution }) => {
      if (subsitution && !staffs.find(({ name }) => name === get(subsitution, 'staff.name'))) {
        staffs.push(subsitution.staff);
      } else if (classInfo) {
        if (get(classInfo, 'type') && report.classTypes) {
          const classType = find(report.classTypes, ({ _id }) => _id === get(classInfo, 'type'));
          if (classType) {
            set(classInfo, 'type', classType);
          }
        }
        const existStaff = find(report.staffs, ({ _id }) => _id === get(classInfo, 'staff'));
        if (existStaff) {
          set(classInfo, 'staff', existStaff);
        }
        if (existStaff && !staffs.find(({ name }) => name === existStaff.name)) {
          staffs.push(existStaff);
        }
      }
    });

    each(staffs, (staffObj) => {
      console.log('Staff:', staffObj);
      const adultClass = filter(report.classes, ({ classInfo }) => get(classInfo, ['type', 'name']) && get(classInfo, ['type', 'name']).includes('成人') && get(classInfo, ['staff', 'name']) === staffObj.name);
      const kidClass = filter(report.classes, ({ classInfo }) => get(classInfo, ['type', 'name']) && get(classInfo, ['type', 'name']).includes('兒童') && get(classInfo, ['staff', 'name']) === staffObj.name);
      staffObj.adultCount = filter(adultClass, ({ attendance }) => attendance.length > 0).length;
      staffObj.adultClass = adultClass;
      staffObj.kidCount = kidClass.length;
      staffObj.kidClass = kidClass;

      const { commissionHeadCountStart, commissionHeadCountEnd, commissionHeadCount } = staffObj;
      let commission = 0;
      each(adultClass, ({ attendance }) => {
        if (!isEmpty(attendance)) {
          const headCount = attendance.length;
          if (headCount >= commissionHeadCountStart) {
            commission += commissionHeadCount * (headCount < commissionHeadCountEnd ? headCount - commissionHeadCountStart : commissionHeadCountEnd);
          }
        }
      });
      staffObj.commission = commission;
      staffObj.total = (staffObj.adultCount + staffObj.kidCount) * get(staffObj, 'basic') + commission;
    });
    report.staffs = staffs;
    console.log(report);
    setReports([report]);
  };

  const getListData = async ({
    modelName, sortField, sort = 'asc', page = 1, pageSize = 100, filter: filterObj,
  }) => {
    meta.fetching = true;
    setMeta(cloneDeep(meta));
    const { records } = await Model.list(modelName, {
      filter: filterObj, sortField, sort, page, pageSize,
    });
    meta.fetching = false;
    meta[modelName] = cloneDeep(records);
    setMeta(cloneDeep(meta));
  };

  const getShopReport = async (shop) => {
    const res = await Model.list(`shop/${shop}/revenue/${selectedDate}`);
    processReport(res);
  };

  const getShopDailyReport = async (shop) => {
    const res = await Model.list(`shop/${shop}/revenue/daily/${selectedDate}`);
    processReport(res);
  };

  const getShops = async () => {
    const res = await Model.list('shop');
    setShops(res);
    const promises = map(res, async (shop) => getShopReport(shop._id));
    const results = Promise.all(promises);
    setReports(results);
  };

  const getClassInfo = (id) => Model.find('class/info', id);

  const getAttendanceReport = async (selectedMember) => {
    if (!selectedMember) {
      return;
    }
    const res = await Model.list('attendance');
    const attended = [];
    each(res, (classObj) => {
      const { attendance } = classObj;
      if (attendance && attendance.length) {
        const found = find(attendance, ({ member }) => isEqual(`${member}`, `${selectedMember._id}`));
        if (found) {
          attended.push({
            ...classObj,
            attended: found,
          });
        }
      }
    });

    const promises = map(attended, ({ classInfo }) => getClassInfo(classInfo));
    const classInfos = await Promise.all(promises);
    meta.classInfos = classInfos;
    meta.attended = map(attended, (att) => {
      const found = find(classInfos, ({ _id }) => isEqual(`${att.classInfo}`, `${_id}`));
      att.info = found;
      return att;
    });
    setMeta(cloneDeep(meta));
  };

  const handleMemberSearch = async (value) => {
    await getListData({ modelName: 'member', sortField: 'memberId', filter: { $or: [{ memberId: { $regex: value, $options: 'i' } }, { mobile: { $regex: value, $options: 'i' } }] } });
  };

  const onSelectMember = async (value) => {
    const member = find(meta.member, ({ memberId }) => isEqual(memberId, value));
    if (!member) {
      return;
    }
    meta.selectedMember = member;
    const packages = await Model.list(`member/package/search/${member._id}`);
    meta.selectedMember.packages = packages;
    setMeta(cloneDeep(meta));

    getAttendanceReport(member);
  };
  const onDateChange = (value) => {
    setSelectedDate(value.format('YYYY-MM-DD'));
  };

  const onShopChange = (value) => {
    setSelectedShop(value);
  };

  const dailyReport = (key = '') => map(reports, (rep) => (
    <div key="daily" style={{ marginTop: 32 }}>
      <h4>{`每日營業額報表${key ? '()' : ''}`}</h4>
      <Row className="report" gutter={16}>
        <Table
          rowKey="month"
          pagination={false}
          columns={[{ title: '日期', dataIndex: 'from' }, {
            title: '收入',
            className: 'column-money',
            dataIndex: 'totalIncome',
          }, {
            title: '支出',
            className: 'column-money',
            dataIndex: 'totalOutcome',
          },
          ]}
          dataSource={[{
            ...rep,
            month: moment(rep.month, 'YYYY-MM').format('MMMM'),
            totalIncome: `$${round(sumBy(rep.purchases, 'amount') + sumBy(rep.income, 'amount'), 0)}`,
            totalOutcome: `$${round(sumBy(rep.outcome, 'amount'), 0)}`,
          }]}
        />
      </Row>
      <Divider />
      <Table
        rowKey="_id"
        pagination={false}
        columns={[{ title: '收入項目名稱', dataIndex: 'name' }, {
          title: '金額',
          className: 'column-money',
          dataIndex: 'amount',
        }]}
        dataSource={map(concat(rep.income, map(rep.purchases, ({
          _id, package: packages, member, amount,
        }) => ({
          _id,
          name: `${member.memberId} - ${packages.name}`,
          amount,
        }))), ({ _id, name, amount }) => ({
          _id,
          name,
          amount: `$${amount}`,
        }))}
      />
      <Table
        rowKey="_id"
        pagination={false}
        columns={[{ title: '支出項目名稱', dataIndex: 'name' }, {
          title: '金額',
          className: 'column-money',
          dataIndex: 'amount',
        }]}
        dataSource={map(rep.outcome, ({ _id, name, amount }) => ({
          _id,
          name,
          amount: `-$${amount}`,
        }))}
      />
    </div>
  ));

  const monthlyReport = (key = 'total') => map(reports, (rep) => (
    <div key="monthly" style={{ marginTop: 32 }}>
      <h4>{`每月收入報告${key ? '()' : ''}`}</h4>
      <Row className="report" gutter={16}>
        <Table
          rowKey="month"
          pagination={false}
          columns={[{ title: '月份', dataIndex: 'month' },
            {
              title: '總收入',
              className: 'column-money',
              dataIndex: 'totalIncome',
            },
            {
              title: '總支出',
              className: 'column-money',
              dataIndex: 'totalOutcome',
            },
            {
              title: '營利',
              className: 'column-money',
              dataIndex: 'totalProfit',
            }]}
          dataSource={[{
            ...rep,
            month: moment(rep.month, 'YYYY-MM').format('MMMM'),
            totalIncome: `$${round(sumBy(rep.purchases, 'amount') + sumBy(rep.income, 'amount'), 0)}`,
            totalOutcome: `$${round(sumBy(rep.staffs, 'total') + sumBy(rep.outcome, 'amount'), 0)}`,
            totalProfit: `$${round(sumBy(rep.purchases, 'amount') + sumBy(rep.income, 'amount') - (sumBy(rep.staffs, 'total') + sumBy(rep.outcome, 'amount')), 0)}`,
          }]}
        />
      </Row>
      <Divider />
      <Table
        rowKey="name"
        pagination={false}
        expandedRowRender={
          ({
            adultClass, kidClass, basic, commissionHeadCountStart, commissionHeadCount, commissionHeadCountEnd,
          }) => (
            <div>
              {adultClass.length ? <p>成人班</p> : <p />}
              {map(adultClass, ({ date, classInfo, attendance }) => (
                <p>
                  <span>
                    日期：
                    {date}
                    {' '}
                    -
                    {classInfo.startTime}
                  </span>
&nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    人數：
                    {attendance.length}
                  </span>
&nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    底薪：$
                    {basic}
                  </span>
&nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    人數佣金：$
                    {attendance.length >= commissionHeadCountStart ? (commissionHeadCount * (attendance.length < commissionHeadCountEnd ? attendance.length - commissionHeadCountStart : commissionHeadCountEnd)) : 0}
                  </span>
                </p>
              ))}

              {kidClass.length ? <p>兒童班</p> : <p />}
              {map(kidClass, ({ date, classInfo, attendance }) => (
                <p>
                  <span>
                    日期：
                    {date}
                    {' '}
                    -
                    {classInfo.startTime}
                  </span>
&nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    人數：
                    {attendance.length}
                  </span>
&nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    底薪：$
                    {basic}
                  </span>
                </p>
              ))}
            </div>
          )
        }
        columns={[{ title: '導師', dataIndex: 'name' },
          {
            title: t('STAFF.BASIC'),
            className: 'column-money',
            dataIndex: 'basic',
          },
          {
            title: '本月成人班',
            dataIndex: 'adultCount',
          },
          {
            title: '本月兒童班',
            dataIndex: 'kidCount',
          },
          {
            title: t('STAFF.HEADCOUNT'),
            className: 'column-money',
            dataIndex: 'commission',
          },
          {
            title: '合計',
            className: 'column-money',
            dataIndex: 'total',
          },
        ]}
        dataSource={rep.staffs}
      />
      <Table
        rowKey="_id"
        pagination={false}
        columns={[{ title: '收入項目名稱', dataIndex: 'name' }, {
          title: '金額',
          className: 'column-money',
          dataIndex: 'amount',
        }]}
        dataSource={map(concat(rep.income, map(rep.purchases, ({
          _id, package: packages, member, amount,
        }) => ({
          _id,
          name: `${member.memberId} - ${packages.name}`,
          amount,
        }))), ({ _id, name, amount }) => ({
          _id,
          name,
          amount: `$${amount}`,
        }))}
      />
      <Table
        rowKey="_id"
        pagination={false}
        columns={[{ title: '支出項目名稱', dataIndex: 'name' }, {
          title: '金額',
          className: 'column-money',
          dataIndex: 'amount',
        }]}
        dataSource={map(rep.outcome, ({ _id, name, amount }) => ({
          _id,
          name,
          amount: `-$${amount}`,
        }))}
      />
    </div>
  ));

  const attendanceReport = (key = 'total') => (
    <div key="attended" style={{ marginTop: 32 }}>
      <h4>{`會員出席報表${key ? '()' : ''}`}</h4>
      <Table
        rowKey="name"
        pagination={false}
        columns={[{ title: '日期', dataIndex: 'date' },
          {
            title: t('CLASSINFO.TYPE'),
            dataIndex: 'info.type.name',
          },
          {
            title: t('CLASSINFO.STYLE'),
            dataIndex: 'info.style.name',
          },
          {
            title: t('CLASSINFO.LEVEL'),
            dataIndex: 'info.level.name',
          },
          {
            title: t('CLASSINFO.STAFF'),
            dataIndex: 'info.staff.name',
          },
          {
            title: t('CLASSINFO.STARTTIME'),
            dataIndex: 'info.startTime',
          },
        ]}
        dataSource={meta.attended}
      />
    </div>
  );

  const removeProperty = (obj) => {
    Object.keys(obj).forEach((x) => {
      if (isPlainObject(obj[x])) {
        removeProperty(obj[x]);
      } else if (isArray(obj)) {
        each(obj, (itm) => {
          removeProperty(itm);
        });
      }
      delete obj[x];
    });
  };

  const handleTabChange = (event) => {
    console.log(event);
  };

  useEffect(() => {
    const { shop } = Global.getInstance().getStore();
    if (!shop && !selectedShop) {
      getShops();
    } else {
      switch (report) {
        case 'report1': getShopReport(shop || selectedShop);
          break;
        case 'report2': getShopDailyReport(shop || selectedShop);
          break;
        case 'report3': getAttendanceReport(meta.selectedMember);
          break;
        default: getShopReport(shop || selectedShop);
      }
    }
  }, [content, refresh, selectedDate, selectedShop]);

  return (
    <div>
      {content
        ? (
          <Card>
            {shops
            && (
            <Select style={{ width: '25%' }} onChange={onShopChange} value={selectedShop}>
              {map(shops, (shop) => <Select.Option value={shop._id}>{shop.name}</Select.Option>)}
            </Select>
            )}
            {report === 'report1' && <DatePicker.MonthPicker onChange={onDateChange} value={moment(selectedDate)} />}
            {report === 'report2' && <DatePicker onChange={onDateChange} value={moment(selectedDate)} />}
            {report === 'report3' && (
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="搜尋會員"
              value={(meta.selectedMember || {}).memberId}
              filterOption={false}
              notFoundContent={meta.fetching ? <Spin size="small" /> : <Empty description="找不到會員" />}
              onChange={onSelectMember}
              onSearch={handleMemberSearch}
            >
              {meta.member && map(meta.member, ({ memberId, mobile, name }) => <Option value={memberId}>{`${memberId} - ${name} - ${mobile}`}</Option>)}
            </Select>
            )}
            <Tabs defaultActiveKey="total" onChange={handleTabChange}>
              {map([''], (key) => (
                <Tabs.TabPane tab={key || '所有'} key={key || 'total'}>
                  {report === 'report1' && monthlyReport(key)}
                  {report === 'report2' && dailyReport(key)}
                  {report === 'report3' && attendanceReport(key)}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </Card>
        )
        : <Empty />}
    </div>
  );
};

export default Report;
