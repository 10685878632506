/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  cloneDeep, includes, filter, find, get, map, sumBy,
  isEqual, remove,
  sortBy, last, groupBy,
} from 'lodash';
import {
  Descriptions, Empty, Row, Col, Select,
  Button, Modal, Spin, Checkbox,
  Menu, DatePicker, PageHeader, Table,
} from 'antd';
import moment from 'moment';
import Model from '../services/model';
import Global from '../commons/Global';

const { Option } = Select;

const getContainer = (content, pipe) => {
  const {
    meta, onCurrentDateChange, handleSelectClass, showDialog, handleClose, handleMemberSearch, onSelectMember, handleAddMember,
    handleStyleChange, handleStaffChange, onSelectStyle, onSelectStaff, handleCancelClass, handlePurchase, confirmPurchase,
    onSelectPackage, onSelectPaymentMethod, handleCheck, handleRemove, onSelectClassInfo, handleKidRemove,
  } = pipe;
  const {
    currentDate, selectedClass, member, fetching, selectedMember, isChangingStyle, isChangingStaff, style, staff, package: packages,
    selectedPackage, classInfo, selectedPaymentMethod, selectedClassInfo,
  } = meta;
  const showSubTitle = () => {
    let styleName = get(selectedClass, 'info.style.name');
    let staffName = get(selectedClass, 'info.staff.name');
    const classTypeName = get(selectedClass, 'info.type.name');
    if (selectedClass.attendance && selectedClass.attendance.subsitution) {
      const { style: newStyle, staff: newStaff } = selectedClass.attendance.subsitution;
      if (newStyle) {
        styleName = `${(find(style, ({ _id }) => isEqual(_id, newStyle)) || {}).name}(Sub)`;
      }
      if (newStaff) {
        staffName = `${(find(staff, ({ _id }) => isEqual(_id, newStaff)) || {}).name}(Sub)`;
      }
    }
    if (isChangingStyle) {
      return (
        <div>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="搜尋課堂類型"
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            notFoundContent={fetching ? <Spin size="small" /> : <Empty description="找不到課堂類型" />}
            onChange={onSelectStyle}
          >
            {style && map(style, ({ _id, name }) => <Option value={_id}>{`${name}`}</Option>)}
          </Select>
          {` - ${staffName}`}
        </div>
      );
    }
    if (isChangingStaff) {
      return (
        <div>
          {`${styleName} - `}
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="搜尋導師"
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            notFoundContent={fetching ? <Spin size="small" /> : <Empty description="找不到導師" />}
            onChange={onSelectStaff}
          >
            {staff && map(staff, ({ _id, name }) => <Option value={_id}>{`${name}`}</Option>)}
          </Select>
        </div>
      );
    }
    return `${styleName} - ${staffName} - ${classTypeName}`;
  };
  return (
    <div id="container">
      <Row gutter={16}>
        <Col span={6}>
          <DatePicker size="large" placeholder="請選擇日期" value={currentDate} defaultValue={currentDate} onChange={onCurrentDateChange} />
        </Col>
        <Col span={18}>
          <Button type="primary" onClick={handlePurchase}>買堂</Button>
        </Col>
      </Row>
      <br />
      <Row gutter={16}>
        <Col span={6}>
          <Menu>
            {
              meta['class/info'] && meta['class/info'].length
                ? map(meta['class/info'], (classObj) => (
                  <Menu.Item onClick={() => handleSelectClass(classObj)}>
                    {`${classObj.startTime} - ${get(classObj, 'style.name', '')}`}
                  </Menu.Item>
                ))
                : <Menu.Item disabled>沒有課堂</Menu.Item>
            }
          </Menu>
        </Col>
        <Col span={18}>
          {selectedClass && selectedClass.info && (
            <div
              style={{
                border: '1px solid #ccc',
              }}
            >
              <PageHeader
                title={`${selectedClass.attendance && selectedClass.attendance.cancel ? '(已取消)' : ''}${currentDate.format('MMMM DD (ddd)')} ${selectedClass.info.startTime}`}
                subTitle={showSubTitle()}
                extra={[
                  <Button key="3" type="primary" onClick={handleStyleChange}>{isChangingStyle ? '取消變更課堂類型' : '變更課堂類型'}</Button>,
                  <Button key="2" type="primary" onClick={handleStaffChange}>{isChangingStaff ? '取消變更導師' : '變更導師'}</Button>,
                  <Button key="1" type={selectedClass.attendance && selectedClass.attendance.cancel ? 'primary' : 'danger'} onClick={handleCancelClass}>
                    {selectedClass.attendance && selectedClass.attendance.cancel ? '恢復課堂' : '取消課堂'}
                  </Button>,
                ]}
              />
              <Row gutter={[16, 16]} style={{ padding: 16 }} hidden={selectedClass.attendance && selectedClass.attendance.cancel}>
                <Col span={6}>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="搜尋會員"
                    value={(selectedMember || {}).memberId}
                    filterOption={false}
                    notFoundContent={fetching ? <Spin size="small" /> : <Empty description="找不到會員" />}
                    onChange={onSelectMember}
                    onSearch={handleMemberSearch}
                  >
                    {member && map(member, ({ memberId, mobile, name }) => <Option value={memberId}>{`${memberId} - ${name} - ${mobile}`}</Option>)}
                  </Select>
                </Col>
                <Col span={6}><Button type="primary" disabled={!selectedMember} onClick={handleAddMember}>添加上課會員</Button></Col>
                <Col span={12}>
                  {
                    selectedMember && (
                      <Descriptions title="會員資料">
                        <Descriptions.Item label="姓名">{selectedMember.name}</Descriptions.Item>
                        <Descriptions.Item label="會員編號">{selectedMember.memberId}</Descriptions.Item>
                        <Descriptions.Item label="電話">{selectedMember.mobile}</Descriptions.Item>
                        <Descriptions.Item label="剩餘課堂">
                          {
                            selectedMember.packagesGroup && map(selectedMember.packagesGroup, (packagesArr, classTypeName) => <p>{`${(packagesArr && sumBy(packagesArr, 'remains')) || 0} (${classTypeName})`}</p>)
                          }
                        </Descriptions.Item>
                        <Descriptions.Item label="到期日">
                          {
                            selectedMember.packagesGroup && map(selectedMember.packagesGroup, (packagesArr, classTypeName) => <span style={{ color: moment(last(sortBy(filter(map(packagesArr, ({ expiryDT, remains, package: { freePass } }) => (remains > 0 || freePass) && expiryDT), (d) => !!d), (d) => d))).isSameOrBefore() ? 'red' : 'black' }}>{`${moment(last(sortBy(filter(map(packagesArr, ({ expiryDT, remains, package: { freePass } }) => (remains > 0 || freePass) && expiryDT), (d) => !!d), (d) => d))).format('YYYY-MM-DD')} (${classTypeName})`}</span>)
                          }
                        </Descriptions.Item>
                      </Descriptions>
                    )
                  }
                </Col>
              </Row>
              {selectedClass && selectedClass.attendance && (
                <div style={{ padding: 16 }}>
                  <Table
                    dataSource={filter(selectedClass.attendance.attendance, (att) => !att.deleted)}
                    columns={includes(selectedClass.info.type.name, '兒童')
                      ? [
                        { title: '會員編號', dataIndex: 'member.memberId' },
                        { title: '會員名稱', dataIndex: 'member.name' },
                        { title: '剩餘堂數', dataIndex: 'memberPackage.remains' },
                        { title: '到期日', dataIndex: 'memberPackage.expiryDT', render: (value) => moment(value).format('YYYY-MM-DD') },
                        { title: '出席', dataIndex: 'attend', render: (value, record) => <Checkbox checked={value} onChange={() => handleCheck('attend', value, record, true)} /> },
                        { title: '請假', dataIndex: 'leave', render: (value, record) => <Checkbox checked={value} onChange={() => handleCheck('leave', value, record)} /> },
                        { title: '補堂', dataIndex: 'compensate', render: (value, record) => <Checkbox checked={value} onChange={() => handleCheck('compensate', value, record)} /> },
                        { title: '', dataIndex: 'operation', render: (value, record) => <Button type="danger" onClick={() => handleKidRemove(record)}>移除</Button> },
                      ]
                      : [
                        { title: '會員編號', dataIndex: 'member.memberId' },
                        { title: '會員名稱', dataIndex: 'member.name' },
                        { title: '出席', dataIndex: 'attend', render: (value) => <Checkbox checked={value} disabled /> },
                        { title: '', dataIndex: 'operation', render: (value, record) => <Button type="danger" onClick={() => handleRemove(record)}>移除</Button> },
                      ]}
                  />
                </div>
              )}
            </div>
          )}
        </Col>
      </Row>
      <Modal
        width="95%"
        style={{ top: 20 }}
        bodyStyle={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
        title="購買課堂套票"
        visible={!!showDialog}
        onOk={() => confirmPurchase()}
        onCancel={() => handleClose()}
        footer={[
          <Button key="back" onClick={() => handleClose()}>
            取消
          </Button>,
          <Button key="submit" type="primary" disabled={!selectedMember} loading={fetching} onClick={() => confirmPurchase()}>
            購買
          </Button>,
        ]}
      >
        <Row gutter={16} style={{ width: '100%' }}>
          <Col span={24}>
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="搜尋會員"
              filterOption={false}
              value={(selectedMember || {}).memberId}
              notFoundContent={fetching ? <Spin size="small" /> : <Empty description="找不到會員" />}
              onChange={onSelectMember}
              onSearch={handleMemberSearch}
            >
              {member && map(member, ({ memberId, mobile, name }) => <Option value={memberId}>{`${memberId} - ${name} - ${mobile}`}</Option>)}
            </Select>
          </Col>
          <Col span={24} style={{ padding: 16 }}>
            {
              selectedMember && (
                <Descriptions title="會員資料">
                  <Descriptions.Item label="姓名">{selectedMember.name}</Descriptions.Item>
                  <Descriptions.Item label="會員編號">{selectedMember.memberId}</Descriptions.Item>
                  <Descriptions.Item label="電話">{selectedMember.mobile}</Descriptions.Item>
                  <Descriptions.Item label="剩餘課堂">
                    {
                      selectedMember.packagesGroup && map(selectedMember.packagesGroup, (packagesArr, classTypeName) => <p>{`${(packagesArr && sumBy(packagesArr, 'remains')) || 0} (${classTypeName})`}</p>)
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label="到期日">
                    {
                      selectedMember.packagesGroup && map(selectedMember.packagesGroup, (packagesArr, classTypeName) => <span style={{ color: moment(last(sortBy(filter(map(packagesArr, ({ expiryDT, remains, package: { freePass } }) => (remains > 0 || freePass) && expiryDT), (d) => !!d), (d) => d))).isSameOrBefore() ? 'red' : 'black' }}>{`${moment(last(sortBy(filter(map(packagesArr, ({ expiryDT, remains, package: { freePass } }) => (remains > 0 || freePass) && expiryDT), (d) => !!d), (d) => d))).format('YYYY-MM-DD')} (${classTypeName})`}</span>)
                    }
                  </Descriptions.Item>
                </Descriptions>
              )
            }
          </Col>
          <Col span={6} style={{ padding: 16 }}>
            <Select
              style={{ width: '100%' }}
              placeholder="套票"
              notFoundContent={fetching ? <Spin size="small" /> : <Empty description="找不到套票" />}
              value={(selectedPackage || {})._id}
              onChange={onSelectPackage}
            >
              {packages && map(packages, ({
                _id, name, price,
              }) => <Option value={_id}>{`${name} - $${price}`}</Option>)}
            </Select>
          </Col>
          <Col span={12} style={{ padding: 16 }}>
            {selectedPackage && includes(selectedPackage.classType.name, '兒童') && (
              <Select
                style={{ width: '100%' }}
                placeholder="指定課堂"
                value={selectedClassInfo}
                onChange={onSelectClassInfo}
              >
                {classInfo && classInfo.length && map(classInfo, ({
                  _id, weekday, startTime, style: _style, staff: _staff,
                }) => <Option value={_id}>{`${_style.name} - ${_staff.name} - ${weekday} - ${startTime}`}</Option>)}
              </Select>
            )}
          </Col>
          <Col span={6} style={{ padding: 16 }}>
            <Select
              style={{ width: '100%' }}
              placeholder="付款方式"
              defaultValue="CASH"
              value={selectedPaymentMethod || 'CASH'}
              onChange={onSelectPaymentMethod}
            >
              <Option value="CASH">現金</Option>
              <Option value="EPS">EPS</Option>
              <Option value="CC">信用卡</Option>
              <Option value="PAYME">PayMe</Option>
            </Select>
          </Col>
          <Col span={24} style={{ padding: 16 }}>
            {selectedPackage && (
              <Descriptions title="套票資料">
                <Descriptions.Item label="套票名稱">{selectedPackage.name}</Descriptions.Item>
                <Descriptions.Item label="價錢">{selectedPackage.price}</Descriptions.Item>
                <Descriptions.Item label="有效期至">{moment().endOf('day').add(selectedPackage.validDays, 'd').format('YYYY-MM-DD')}</Descriptions.Item>
                <Descriptions.Item label="堂數">{selectedPackage.quantity}</Descriptions.Item>
              </Descriptions>
            )}
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

const Dashboard = (props) => {
  const {
    pipe, content, refresh,
  } = props;
  const { translate } = pipe;
  const { t } = translate;
  const [showDialog, setShowDialog] = useState(false);
  const [meta, setMeta] = useState({});

  const getListData = async ({
    modelName, sortField, sort = 'asc', page = 1, pageSize = 100, filter: filterObj,
  }) => {
    meta.fetching = true;
    setMeta(cloneDeep(meta));
    const { records } = await Model.list(modelName, {
      filter: filterObj, sortField, sort, page, pageSize,
    });
    meta.fetching = false;
    meta[modelName] = cloneDeep(records);
    setMeta(cloneDeep(meta));
  };

  const getClassInfo = async (value) => {
    const classInfo = await Model.list('class/info', { filter: { type: value, expiryDT: { $gt: (meta.currentDate || moment()).endOf('date').toISOString() }, validDT: { $lte: (meta.currentDate || moment()).endOf('date').toISOString() } } });
    meta.classInfo = classInfo;
    console.log(meta);
    setMeta(cloneDeep(meta));
  };

  const getShopsData = async () => {
    const { shop } = Global.getInstance().getStore();
    const data = await Model.list('shop', { _id: shop });
    meta.shops = cloneDeep(data);
    setMeta(cloneDeep(meta));
  };

  const handleClose = async (close = false) => {
    // clearMeta();
    meta.selectedMember = undefined;
    meta.selectedPackage = undefined;
    meta.selectedClassInfo = undefined;
    meta.selectedPaymentMethod = undefined;
    meta.member = [];
    setMeta(cloneDeep(meta));
    if (close) {
      await getListData({ modelName: 'package' });
    }
    setShowDialog(close);
  };

  const onSelect = (val, item) => {
    meta[item.valueKey] = val;
    const found = find(get(meta, `${item.dataSource}Orig`), (r) => get(r, item.field) === val);
    if (found && item.selectedObj) {
      meta[item.selectedObj] = found;
      // TODO: to be generic
      // getPackagesData(found._id);
    }
    setMeta(cloneDeep(meta));
  };
  const onSearch = (val, item) => {
    meta[item.valueKey] = val;
    meta[item.dataSource] = filter(get(meta, `${item.dataSource}Orig`), (r) => includes(get(r, item.field), val));
    const found = find(meta[`${item.dataSource}Orig`], (r) => get(r, item.field) === val);
    if (found) {
      meta[item.selectedObj] = found;
      // TODO: to be generic
      // getPackagesData(found._id);
    }
    setMeta(cloneDeep(meta));
  };

  const onCurrentDateChange = async (value) => {
    meta.currentDate = value;
    meta.selectedClass = undefined;
    meta.selectedMember = undefined;
    meta.selectedPackage = undefined;
    meta.selectedPaymentMethod = undefined;
    meta.selectedClassInfo = undefined;
    setMeta(cloneDeep(meta));
    await getListData({ modelName: 'class/info', sortField: 'startTime', filter: { weekday: value.isoWeekday() % 7, expiryDT: { $gt: (meta.currentDate || moment()).endOf('date').toISOString() }, validDT: { $lte: (meta.currentDate || moment()).endOf('date').toISOString() } } });
  };

  const handleSelectClass = async (classObj) => {
    try {
      const attendance = await Model.find(`class/find/${meta.currentDate.format('YYYY-MM-DD')}`, classObj._id);
      meta.selectedClass = { info: classObj, attendance };
    } catch (error) {
      meta.selectedClass = { info: classObj };
    } finally {
      setMeta(cloneDeep(meta));
    }
  };

  const handleMemberSearch = async (value) => {
    await getListData({ modelName: 'member', sortField: 'memberId', filter: { $or: [{ memberId: { $regex: value, $options: 'i' } }, { mobile: { $regex: value, $options: 'i' } }] } });
  };

  const findLatestExpiryDT = (packages) => moment(Math.max(...packages.map(({ expiryDT }) => moment(expiryDT))));

  const onSelectMember = async (value) => {
    const member = find(meta.member, ({ memberId }) => isEqual(memberId, value));
    if (!member) {
      return;
    }
    meta.selectedMember = member;
    const packages = await Model.list(`member/package/search/${member._id}`);
    const expiryDT = findLatestExpiryDT(packages);
    if (moment().diff(expiryDT, 'months') > 2) {
      await Promise.all(map(packages, async ({ _id }) => {
        await Model.update('member/package', _id, { deleted: true });
      }));
      meta.selectedMember.packages = [];
      meta.selectedMember.packagesGroup = {};
    } else {
      meta.selectedMember.packages = packages;
      meta.selectedMember.packagesGroup = groupBy(packages, ({ package: pObj }) => pObj.classType.name);
    }
    setMeta(cloneDeep(meta));
  };

  const hasFreePass = (member) => {
    if (!member) return false;
    if (member.packages) {
      const arr = filter(member.packages, ({ expiryDT, package: { freePass } }) => moment(expiryDT).isAfter() && freePass);
      return arr.length > 0;
    }
    return false;
  };

  const handleAddMember = async () => {
    if (!meta.selectedMember || !meta.selectedClass) {
      return;
    }
    const { currentDate, selectedMember: member, selectedClass } = meta;
    try {
      const result = await Model.create('attendance',
        {
          date: currentDate.format('YYYY-MM-DD'), member: member._id, classInfo: selectedClass.info._id, freePass: hasFreePass(member),
        });
      if (result) {
        pipe.showNotify({ type: 'success', message: t('SUCCESS'), description: t('SETMODEL.SUCCESS') });
      }
    } catch (error) {
      pipe.showNotify({ type: 'error', message: t('ERROR'), description: error && t(error.message) });
    } finally {
      try {
        const attendance = await Model.find(`class/find/${meta.currentDate.format('YYYY-MM-DD')}`, selectedClass.info._id);
        meta.selectedClass = { info: selectedClass.info, attendance };
      } catch (error) {
        meta.selectedClass = { info: selectedClass.info };
      } finally {
        meta.selectedMember = undefined;
        setMeta(cloneDeep(meta));
      }
    }
  };

  const handleStyleChange = () => {
    meta.isChangingStyle = !meta.isChangingStyle;
    meta.isChangingStaff = false;
    setMeta(cloneDeep(meta));
  };

  const handleStaffChange = () => {
    meta.isChangingStyle = false;
    meta.isChangingStaff = !meta.isChangingStaff;
    setMeta(cloneDeep(meta));
  };

  const onSelectStyle = async (value) => {
    const { currentDate, selectedClass } = meta;
    try {
      const result = await Model.create('attendance', { date: currentDate.format('YYYY-MM-DD'), style: value, classInfo: selectedClass.info._id });
      if (result) {
        pipe.showNotify({ type: 'success', message: t('SUCCESS'), description: t('SETMODEL.SUCCESS') });
      }
    } catch (error) {
      pipe.showNotify({ type: 'error', message: t('ERROR'), description: error && t(error.message) });
    } finally {
      try {
        const attendance = await Model.find(`class/find/${meta.currentDate.format('YYYY-MM-DD')}`, selectedClass.info._id);
        meta.selectedClass = { info: selectedClass.info, attendance };
      } catch (error) {
        meta.selectedClass = { info: selectedClass.info };
      } finally {
        meta.isChangingStyle = false;
        meta.refresh = !meta.refresh;
        setMeta(cloneDeep(meta));
      }
    }
  };

  const onSelectStaff = async (value) => {
    const { currentDate, selectedClass } = meta;
    try {
      const result = await Model.create('attendance', { date: currentDate.format('YYYY-MM-DD'), staff: value, classInfo: selectedClass.info._id });
      if (result) {
        pipe.showNotify({ type: 'success', message: t('SUCCESS'), description: t('SETMODEL.SUCCESS') });
      }
    } catch (error) {
      pipe.showNotify({ type: 'error', message: t('ERROR'), description: error && t(error.message) });
    } finally {
      try {
        const attendance = await Model.find(`class/find/${meta.currentDate.format('YYYY-MM-DD')}`, selectedClass.info._id);
        meta.selectedClass = { info: selectedClass.info, attendance };
      } catch (error) {
        meta.selectedClass = { info: selectedClass.info };
      } finally {
        meta.isChangingStaff = false;
        meta.refresh = !meta.refresh;
        setMeta(cloneDeep(meta));
      }
    }
  };

  const handleCancelClass = async () => {
    const { currentDate, selectedClass, selectedMember: member } = meta;
    try {
      const result = await Model.create('attendance',
        {
          date: currentDate.format('YYYY-MM-DD'),
          cancel: !(selectedClass.attendance && selectedClass.attendance.cancel),
          classInfo: selectedClass.info._id,
          freePass: hasFreePass(member),
        });
      if (result) {
        pipe.showNotify({ type: 'success', message: t('SUCCESS'), description: t('SETMODEL.SUCCESS') });
      }
    } catch (error) {
      pipe.showNotify({ type: 'error', message: t('ERROR'), description: error && t(error.message) });
    } finally {
      try {
        const attendance = await Model.find(`class/find/${meta.currentDate.format('YYYY-MM-DD')}`, selectedClass.info._id);
        meta.selectedClass = { info: selectedClass.info, attendance };
      } catch (error) {
        meta.selectedClass = { info: selectedClass.info };
      } finally {
        meta.isChangingStaff = false;
        meta.refresh = !meta.refresh;
        setMeta(cloneDeep(meta));
      }
    }
  };

  const onSelectPackage = async (value) => {
    meta.selectedPackage = find(meta.package, ({ _id }) => isEqual(_id, value));
    await getClassInfo(get(meta, 'selectedPackage.classType._id', meta.selectedPackage.classType));
    setMeta(cloneDeep(meta));
  };

  const onSelectPaymentMethod = (value) => {
    meta.selectedPaymentMethod = value;
    setMeta(cloneDeep(meta));
  };

  const onSelectClassInfo = (value) => {
    meta.selectedClassInfo = value;
    setMeta(cloneDeep(meta));
  };

  const handlePurchase = () => {
    handleClose(true);
  };

  const confirmPurchase = async () => {
    const { shop, account } = Global.getInstance().getStore();
    const {
      selectedMember, selectedPackage, selectedPaymentMethod, selectedClassInfo,
    } = meta;
    if (selectedMember && selectedPackage) {
      try {
        const result = await Model.create('purchase', {
          package: selectedPackage._id, member: selectedMember._id, shop, handleBy: account._id, amount: selectedPackage.price, payBy: selectedPaymentMethod || 'CASH',
        });
        const memberPackage = await Model.create('member/package', { member: selectedMember._id, package: selectedPackage._id, classInfo: selectedClassInfo });
        if (memberPackage && result) {
          pipe.showNotify({ type: 'success', message: t('SUCCESS'), description: t('SETMODEL.SUCCESS') });
          handleClose(false);
        }
      } catch (error) {
        pipe.showNotify({ type: 'error', message: t('ERROR'), description: error && t(error.message) });
      }
    }
  };

  const handleCheck = async (type, value, record, neg = false) => {
    const { selectedClass } = meta;
    const newRecord = find(selectedClass.attendance.attendance, ({ member }) => isEqual(member._id, record.member._id));
    switch (type) {
      case 'attend':
        newRecord.attend = !record.attend;
        if (!value) {
          if (neg || (record.memberPackage && record.memberPackage.remains > 0)) {
            await Model.update('member/package', record.memberPackage._id, { $add: { remains: -1 } });
          }
        } else {
          await Model.update('member/package', record.memberPackage._id, { $add: { remains: 1 } });
        }
        break;
      case 'leave':
        newRecord.leave = !record.leave;
        break;
      case 'compensate':
        newRecord.compensate = !record.compensate;
        break;
      default:
        break;
    }

    try {
      await Model.update('attendance', selectedClass.attendance._id, { attendance: selectedClass.attendance.attendance });
      const attendance = await Model.find(`class/find/${meta.currentDate.format('YYYY-MM-DD')}`, selectedClass.info._id);
      meta.selectedClass = { info: selectedClass.info, attendance };
    } catch (error) {
      meta.selectedClass = { info: selectedClass.info };
    } finally {
      meta.selectedMember = undefined;
      setMeta(cloneDeep(meta));
    }
  };
  const handleKidRemove = async (record) => {
    const { selectedClass } = meta;
    const { attendance } = selectedClass;
    try {
      if (!record.freePass) {
        await Model.update('member/package', record.memberPackage._id || record.memberPackage, { deleted: true });
      }
      await Model.update('attendance', attendance._id, {
        attendance: map(attendance.attendance, (att) => {
          if (att.memberPackage._id === record.memberPackage._id || att.memberPackage === record.memberPackage) {
            return {
              ...att,
              deleted: true,
            };
          }
          return att;
        }),
      });
      remove(attendance.attendance, record);
      pipe.showNotify({ type: 'success', message: t('SUCCESS'), description: t('SETMODEL.SUCCESS') });
    } catch (error) {
      pipe.showNotify({ type: 'error', message: t('ERROR'), description: error && t(error.message) });
    } finally {
      setMeta(cloneDeep(meta));
    }
  };

  const handleRemove = async (record) => {
    const { selectedClass } = meta;
    const { attendance } = selectedClass;
    try {
      if (!record.freePass) {
        await Model.update('member/package', record.memberPackage._id || record.memberPackage, { $add: { remains: 1 } });
      }
      remove(attendance.attendance, record);
      await Model.update('attendance', attendance._id, { attendance: attendance.attendance });
      pipe.showNotify({ type: 'success', message: t('SUCCESS'), description: t('SETMODEL.SUCCESS') });
    } catch (error) {
      pipe.showNotify({ type: 'error', message: t('ERROR'), description: error && t(error.message) });
    } finally {
      setMeta(cloneDeep(meta));
    }
  };

  useEffect(() => {
    if (!meta.currentDate) {
      meta.currentDate = moment();
      setMeta(cloneDeep(meta));
    }
    getShopsData();
    getListData({ modelName: 'class/info', sortField: 'startTime', filter: { weekday: meta.currentDate.isoWeekday(), expiryDT: { $gt: meta.currentDate.endOf('date').toISOString() }, validDT: { $lte: meta.currentDate.endOf('date').toISOString() } } });
    getListData({ modelName: 'style' });
    getListData({ modelName: 'staff' });
  }, [content.model, content.items, refresh]);

  return getContainer({}, {
    ...pipe,
    showDialog,
    handleClose,
    onSelect,
    onSearch,
    onCurrentDateChange,
    handleSelectClass,
    handleMemberSearch,
    onSelectMember,
    handleAddMember,
    handleStyleChange,
    handleStaffChange,
    handleCancelClass,
    onSelectStyle,
    onSelectStaff,
    handlePurchase,
    onSelectPackage,
    confirmPurchase,
    onSelectPaymentMethod,
    handleCheck,
    handleRemove,
    handleKidRemove,
    onSelectClassInfo,
    meta,
  });
};

export default Dashboard;
